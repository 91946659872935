import {
  Box,
  Button,
  Flex,
  Title,
  Text,
  TextInput,
  SimpleGrid,
  Stepper,
  Textarea,
  Radio,
  Group,
  Checkbox,
} from '@mantine/core';
import { useLocation } from 'react-router-dom';
import { GradientButton } from 'components/GradientButton';
import evaluationImage from 'assets/evaluation/img03a.png';
import bgImage1 from 'assets/evaluation/bg01A.png';
import bgImage2 from 'assets/evaluation/bg02A.png';
import bgImage3 from 'assets/evaluation/bg03A.png';
import bgImage4 from 'assets/evaluation/bg04A.png';
import { useQuery } from '@tanstack/react-query';
import { API } from 'services';
import { useForm } from '@mantine/form';
import React, { useEffect, useState } from 'react';
import './workaround-google-translate';

export function EvaluationPage() {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const courseRunId = queryParams.get('course_run');

  const [active, setActive] = useState(0);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');

  const [section1, setSection1] = useState([]);
  const [section2, setSection2] = useState([]);
  const [section3, setSection3] = useState([]);

  const allCourse = useQuery(['allCourse'], () => {
    return API.getAllCourse().then((res) => res.data);
  });

  const mandonaly = (
    <span
      className="mantine-103svbs mantine-InputWrapper-required mantine-TextInput-required"
      aria-hidden="true"
    >
      {' '}
      *
    </span>
  );

  const form = useForm({
    initialValues: {
      name: '',
      mobile: '',
      section1_objectives: '',
      section1_perform: '',
      section1_useful: '',
      section1_satisfied: '',
      section1_comments: '',
    },
    validate: {
      name: (value) => (value.length < 2 ? 'Name must have at least 2 letters' : null),
      mobile: (value) =>
        /^[0-9]{8}$/.test(value) ? null : 'Please fill in a valid mobile number (8 digit)',
      section1_objectives: (value) => (value ? null : 'Please select rating'), // Validation for the radio box
      section1_perform: (value) => (value ? null : 'Please select rating'), // Validation for the radio box
      section1_useful: (value) => (value ? null : 'Please select rating'), // Validation for the radio box
      section1_satisfied: (value) => (value ? null : 'Please select rating'), // Validation for the radio box
    },
  });
  const form2 = useForm({
    initialValues: {
      section2_recommended: '',
      section2_helpful: '',
      section2_comments: '',
    },
    validate: {
      section2_recommended: (value) => (value ? null : 'Please select rating'), // Validation for the radio box
      section2_helpful: (value) => (value ? null : 'Please select rating'), // Validation for the radio box
    },
  });
  const form3 = useForm({
    initialValues: {
      section3_knowledgeable: '',
      section3_delivered: '',
      section3_encouraged: '',
      section3_presented: '',
      section3_best: '',
      section3_least: '',
      section3_comments: '',
    },
    validate: {
      section3_knowledgeable: (value) => (value ? null : 'Please select rating'), // Validation for the radio box
      section3_delivered: (value) => (value ? null : 'Please select rating'), // Validation for the radio box
      section3_encouraged: (value) => (value ? null : 'Please select rating'), // Validation for the radio box
      section3_presented: (value) => (value ? null : 'Please select rating'), // Validation for the radio box
      section3_best: (value) => (value.length < 2 ? 'Please fill in at least 2 letters' : null),
      section3_least: (value) => (value.length < 2 ? 'Please fill in at least 2 letters' : null),
    },
  });

  const form4 = useForm({
    initialValues: {
      section4_interest: '',
    },
  });

  const handleSubmitSection1 = (data: any) => {
    setSection1(data);
    setActive(1);
  };
  const handleSubmitSection2 = (data: any) => {
    setSection2(data);
    setActive(2);
  };
  const handleSubmitSection3 = (data: any) => {
    setSection3(data);
    setActive(3);
  };
  const handleSubmitSection4 = (data: any) => {
    return API.evaluationSubmission({
      section1: section1,
      section2: section2,
      section3: section3,
      section4: data,
      courseRunId: courseRunId,
    } as any).then((response) => {
      if (response.data.status) {
        setActive(5);
      } else {
        setTitle(response.data.title);
        setMessage(response.data.message);
        setActive(4);
      }
    });
  };

  const prevStep = () => {
    setActive(active - 1);
  };

  return (
    <Box bg="#f4fafe" w="100%" h="100vh" className="evalution_box">
      <SimpleGrid
        cols={2}
        bg="#e5f7f2"
        className="evalution_question_box"
        p={30}
        breakpoints={[{ maxWidth: 'md', cols: 1 }]}
      >
        <Flex className="flex_align_center">
          <Title order={2}>Learner Evaluation Form</Title>
        </Flex>
        <Flex className="flex_justify_end">
          <img id={'welcome-image'} src={evaluationImage} alt="welcome" />
        </Flex>
      </SimpleGrid>

      <Flex
        bg="#fff"
        className="evalution_question_box"
        p={{ base: 10, sm: 20 }}
        direction="row"
        wrap="wrap"
      >
        <Stepper active={active} onStepClick={setActive} w={'100%'}>
          <Stepper.Step>
            {/* Section 1 */}
            <form onSubmit={form.onSubmit(handleSubmitSection1)} style={{ width: '100%' }}>
              {/* <form style={{ width: '100%' }}> */}
              <Flex bg="#fffcf5" w="100%" p={20} my={10} direction="row" wrap="wrap">
                <Title order={4} my={10}>
                  Personal Particulars
                </Title>
                <Box w="100%">
                  <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
                    <TextInput
                      size="md"
                      withAsterisk
                      label="Name"
                      placeholder="Name"
                      {...form.getInputProps('name')}
                    />
                    <TextInput
                      size="md"
                      withAsterisk
                      label="Registered Mobile No."
                      placeholder="Enter your mobile number"
                      {...form.getInputProps('mobile')}
                    />
                  </SimpleGrid>
                </Box>
              </Flex>

              {/* Q1 */}
              <Flex w="100%" p={20} my={10} direction="row" wrap="wrap" className="question_box">
                <Title order={4} my={10} w="100%">
                  Section 1
                </Title>
                <Text>
                  Evaluation on FirstCom Academy and our Course Curriculum<br></br>For each
                  statement below, please choose the response that best represents your opinion
                </Text>
                <Box w="100%" my={20}>
                  <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
                    <Text fw={700}>
                      The training program has met all of its intended objectives. {mandonaly}
                    </Text>
                    <Flex className="custom_radio flex_align_end flex_justify_end md_flex_justify_center">
                      <Text className="strong_agree" fw={500} ta="center" mx={10}>
                        Strongly<br></br>Agree
                      </Text>
                      <Radio.Group
                        name="section1_objectives"
                        {...form.getInputProps('section1_objectives')}
                      >
                        <Group mt="xs">
                          <Radio value="6" label="6" />
                          <Radio value="5" label="5" />
                          <Radio value="4" label="4" />
                          <Radio value="3" label="3" />
                          <Radio value="2" label="2" />
                          <Radio value="1" label="1" />
                        </Group>
                      </Radio.Group>
                      <Text className="strong_disagree" fw={500} ta="center" mx={10}>
                        Strongly<br></br>Disagree
                      </Text>
                    </Flex>
                  </SimpleGrid>
                  {form.errors.section1_objectives && (
                    <p style={{ color: 'red' }}>{form.errors.section1_objectives}</p>
                  )}
                </Box>
              </Flex>
              {/* Q2 */}
              <Flex w="100%" p={20} my={10} direction="row" wrap="wrap" className="question_box">
                <Box w="100%" my={20}>
                  <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
                    <Text fw={700}>
                      The content is relevant and the knowledge/skills learnt in this program will
                      enable me to perform better in the trained competencies. {mandonaly}
                    </Text>
                    <Flex className="custom_radio flex_align_end flex_justify_end md_flex_justify_center">
                      <Text className="strong_agree" fw={500} ta="center" mx={10}>
                        Strongly<br></br>Agree
                      </Text>
                      <Radio.Group
                        name="section1_perform"
                        {...form.getInputProps('section1_perform')}
                      >
                        <Group mt="xs">
                          <Radio value="6" label="6" />
                          <Radio value="5" label="5" />
                          <Radio value="4" label="4" />
                          <Radio value="3" label="3" />
                          <Radio value="2" label="2" />
                          <Radio value="1" label="1" />
                        </Group>
                      </Radio.Group>
                      <Text className="strong_disagree" fw={500} ta="center" mx={10}>
                        Strongly<br></br>Disagree
                      </Text>
                    </Flex>
                  </SimpleGrid>
                  {form.errors.section1_perform && (
                    <p style={{ color: 'red' }}>{form.errors.section1_perform}</p>
                  )}
                </Box>
              </Flex>
              {/* Q3 */}
              <Flex w="100%" p={20} my={10} direction="row" wrap="wrap" className="question_box">
                <Box w="100%" my={20}>
                  <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
                    <Text fw={700}>
                      I would recommend this program to someone I know who may find this competency
                      useful. {mandonaly}
                    </Text>
                    <Flex className="custom_radio flex_align_end flex_justify_end md_flex_justify_center">
                      <Text className="strong_agree" fw={500} ta="center" mx={10}>
                        Strongly<br></br>Agree
                      </Text>
                      <Radio.Group
                        name="section1_useful"
                        {...form.getInputProps('section1_useful')}
                      >
                        <Group mt="xs">
                          <Radio value="6" label="6" />
                          <Radio value="5" label="5" />
                          <Radio value="4" label="4" />
                          <Radio value="3" label="3" />
                          <Radio value="2" label="2" />
                          <Radio value="1" label="1" />
                        </Group>
                      </Radio.Group>
                      <Text className="strong_disagree" fw={500} ta="center" mx={10}>
                        Strongly<br></br>Disagree
                      </Text>
                    </Flex>
                  </SimpleGrid>
                  {form.errors.section1_useful && (
                    <p style={{ color: 'red' }}>{form.errors.section1_useful}</p>
                  )}
                </Box>
              </Flex>
              {/* Q4 */}
              <Flex w="100%" p={20} my={10} direction="row" wrap="wrap" className="question_box">
                <Box w="100%" my={20}>
                  <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
                    <Text fw={700}>
                      Overall, I am satisfied with the training program. {mandonaly}
                    </Text>
                    <Flex className="custom_radio flex_align_end flex_justify_end md_flex_justify_center">
                      <Text className="strong_agree" fw={500} ta="center" mx={10}>
                        Strongly<br></br>Agree
                      </Text>
                      <Radio.Group
                        name="section1_satisfied"
                        {...form.getInputProps('section1_satisfied')}
                      >
                        <Group mt="xs">
                          <Radio value="6" label="6" />
                          <Radio value="5" label="5" />
                          <Radio value="4" label="4" />
                          <Radio value="3" label="3" />
                          <Radio value="2" label="2" />
                          <Radio value="1" label="1" />
                        </Group>
                      </Radio.Group>
                      <Text className="strong_disagree" fw={500} ta="center" mx={10}>
                        Strongly<br></br>Disagree
                      </Text>
                    </Flex>
                  </SimpleGrid>
                  {form.errors.section1_satisfied && (
                    <p style={{ color: 'red' }}>{form.errors.section1_satisfied}</p>
                  )}
                </Box>
              </Flex>
              {/* Q5 */}
              <Flex w="100%" p={20} my={10} direction="row" wrap="wrap" className="question_box">
                <Box w="100%" my={20}>
                  <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
                    <Text fw={700}>
                      Do you have any comments relating to Firstcom Academy and our Course
                      Curriculum? (Optional)
                    </Text>
                    <Flex className="custom_radio flex_align_end flex_justify_end md_flex_justify_center">
                      <Textarea
                        size="md"
                        name="section1_comments"
                        placeholder="Your Feedback"
                        w="450px"
                        {...form.getInputProps('section1_comments')}
                      ></Textarea>
                    </Flex>
                  </SimpleGrid>
                </Box>
              </Flex>

              <Flex className="flex_justify_end">
                <GradientButton type="submit" mt="sm">
                  Next
                </GradientButton>
              </Flex>
            </form>
          </Stepper.Step>

          <Stepper.Step>
            {/* Section 2 */}
            <form onSubmit={form2.onSubmit(handleSubmitSection2)} style={{ width: '100%' }}>
              {/* Q1 */}
              <Flex w="100%" p={20} my={10} direction="row" wrap="wrap" className="question_box">
                <Title order={4} my={10} w="100%">
                  Section 2
                </Title>
                <Text>
                  Evaluation on our Service Standards<br></br>For each statement below, please
                  choose the response that best represents your opinion
                </Text>
                <Box w="100%" my={20}>
                  <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
                    <Text fw={700}>
                      Our Course Consultants are knowledgeable and recommended the right course.{' '}
                      {mandonaly}
                    </Text>
                    <Flex className="custom_radio flex_align_end flex_justify_end md_flex_justify_center">
                      <Text className="strong_agree" fw={500} ta="center" mx={10}>
                        Strongly<br></br>Agree
                      </Text>
                      <Radio.Group
                        name="section2_recommended"
                        {...form2.getInputProps('section2_recommended')}
                      >
                        <Group mt="xs">
                          <Radio value="6" label="6" />
                          <Radio value="5" label="5" />
                          <Radio value="4" label="4" />
                          <Radio value="3" label="3" />
                          <Radio value="2" label="2" />
                          <Radio value="1" label="1" />
                        </Group>
                      </Radio.Group>
                      <Text className="strong_disagree" fw={500} ta="center" mx={10}>
                        Strongly<br></br>Disagree
                      </Text>
                    </Flex>
                  </SimpleGrid>
                  {form2.errors.section2_recommended && (
                    <p style={{ color: 'red' }}>{form2.errors.section2_recommended}</p>
                  )}
                </Box>
              </Flex>
              {/* Q2 */}
              <Flex w="100%" p={20} my={10} direction="row" wrap="wrap" className="question_box">
                <Box w="100%" my={20}>
                  <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
                    <Text fw={700}>
                      Our Training Support are friendly and helpful and provided good assistance in
                      administrative and operations matters. {mandonaly}
                    </Text>
                    <Flex className="custom_radio flex_align_end flex_justify_end md_flex_justify_center">
                      <Text className="strong_agree" fw={500} ta="center" mx={10}>
                        Strongly<br></br>Agree
                      </Text>
                      <Radio.Group
                        name="section2_helpful"
                        {...form2.getInputProps('section2_helpful')}
                      >
                        <Group mt="xs">
                          <Radio value="6" label="6" />
                          <Radio value="5" label="5" />
                          <Radio value="4" label="4" />
                          <Radio value="3" label="3" />
                          <Radio value="2" label="2" />
                          <Radio value="1" label="1" />
                        </Group>
                      </Radio.Group>
                      <Text className="strong_disagree" fw={500} ta="center" mx={10}>
                        Strongly<br></br>Disagree
                      </Text>
                    </Flex>
                  </SimpleGrid>
                  {form2.errors.section2_helpful && (
                    <p style={{ color: 'red' }}>{form2.errors.section2_helpful}</p>
                  )}
                </Box>
              </Flex>
              {/* Q3 */}
              <Flex w="100%" p={20} my={10} direction="row" wrap="wrap" className="question_box">
                <Box w="100%" my={20}>
                  <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
                    <Text fw={700}>Other comments relating our Service Standards (Optional)</Text>
                    <Flex className="custom_radio flex_align_end flex_justify_end md_flex_justify_center">
                      <Textarea
                        size="md"
                        name="section2_comments"
                        placeholder="Your Feedback"
                        w="450px"
                        {...form2.getInputProps('section2_comments')}
                      ></Textarea>
                    </Flex>
                  </SimpleGrid>
                </Box>
              </Flex>

              <Flex w="100%">
                <Button onClick={prevStep} color="gray" mt="sm">
                  Back
                </Button>
                <Flex className="flex_justify_end" w="100%">
                  <GradientButton type="submit" mt="sm">
                    Next
                  </GradientButton>
                </Flex>
              </Flex>
            </form>
          </Stepper.Step>

          <Stepper.Step>
            {/* Section 3 */}
            <form onSubmit={form3.onSubmit(handleSubmitSection3)} style={{ width: '100%' }}>
              {/* Q1 */}
              <Flex w="100%" p={20} my={10} direction="row" wrap="wrap" className="question_box">
                <Title order={4} my={10} w="100%">
                  Section 3
                </Title>
                <Text>
                  Evaluation on our Trainer Quality<br></br>For each statement below, please choose
                  the response that best represents your opinion
                </Text>
                <Box w="100%" my={20}>
                  <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
                    <Text fw={700}>The trainer is knowledgeable about the topic. {mandonaly}</Text>
                    <Flex className="custom_radio flex_align_end flex_justify_end md_flex_justify_center">
                      <Text className="strong_agree" fw={500} ta="center" mx={10}>
                        Strongly<br></br>Agree
                      </Text>
                      <Radio.Group
                        name="section3_knowledgeable"
                        {...form3.getInputProps('section3_knowledgeable')}
                      >
                        <Group mt="xs">
                          <Radio value="6" label="6" />
                          <Radio value="5" label="5" />
                          <Radio value="4" label="4" />
                          <Radio value="3" label="3" />
                          <Radio value="2" label="2" />
                          <Radio value="1" label="1" />
                        </Group>
                      </Radio.Group>
                      <Text className="strong_disagree" fw={500} ta="center" mx={10}>
                        Strongly<br></br>Disagree
                      </Text>
                    </Flex>
                  </SimpleGrid>
                  {form3.errors.section3_knowledgeable && (
                    <p style={{ color: 'red' }}>{form3.errors.section3_knowledgeable}</p>
                  )}
                </Box>
              </Flex>
              {/* Q2 */}
              <Flex w="100%" p={20} my={10} direction="row" wrap="wrap" className="question_box">
                <Box w="100%" my={20}>
                  <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
                    <Text fw={700}>
                      The trainer delivered discussions and answered questions in a way that is easy
                      to understand. {mandonaly}
                    </Text>
                    <Flex className="custom_radio flex_align_end flex_justify_end md_flex_justify_center">
                      <Text className="strong_agree" fw={500} ta="center" mx={10}>
                        Strongly<br></br>Agree
                      </Text>
                      <Radio.Group
                        name="section3_delivered"
                        {...form3.getInputProps('section3_delivered')}
                      >
                        <Group mt="xs">
                          <Radio value="6" label="6" />
                          <Radio value="5" label="5" />
                          <Radio value="4" label="4" />
                          <Radio value="3" label="3" />
                          <Radio value="2" label="2" />
                          <Radio value="1" label="1" />
                        </Group>
                      </Radio.Group>
                      <Text className="strong_disagree" fw={500} ta="center" mx={10}>
                        Strongly<br></br>Disagree
                      </Text>
                    </Flex>
                  </SimpleGrid>
                  {form3.errors.section3_delivered && (
                    <p style={{ color: 'red' }}>{form3.errors.section3_delivered}</p>
                  )}
                </Box>
              </Flex>
              {/* Q3 */}
              <Flex w="100%" p={20} my={10} direction="row" wrap="wrap" className="question_box">
                <Box w="100%" my={20}>
                  <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
                    <Text fw={700}>
                      The trainer encouraged participation and kept the class attentive and
                      involved. {mandonaly}
                    </Text>
                    <Flex className="custom_radio flex_align_end flex_justify_end md_flex_justify_center">
                      <Text className="strong_agree" fw={500} ta="center" mx={10}>
                        Strongly<br></br>Agree
                      </Text>
                      <Radio.Group
                        name="section3_encouraged"
                        {...form3.getInputProps('section3_encouraged')}
                      >
                        <Group mt="xs">
                          <Radio value="6" label="6" />
                          <Radio value="5" label="5" />
                          <Radio value="4" label="4" />
                          <Radio value="3" label="3" />
                          <Radio value="2" label="2" />
                          <Radio value="1" label="1" />
                        </Group>
                      </Radio.Group>
                      <Text className="strong_disagree" fw={500} ta="center" mx={10}>
                        Strongly<br></br>Disagree
                      </Text>
                    </Flex>
                  </SimpleGrid>
                  {form3.errors.section3_encouraged && (
                    <p style={{ color: 'red' }}>{form3.errors.section3_encouraged}</p>
                  )}
                </Box>
              </Flex>
              {/* Q4 */}
              <Flex w="100%" p={20} my={10} direction="row" wrap="wrap" className="question_box">
                <Box w="100%" my={20}>
                  <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
                    <Text fw={700}>
                      The trainer presented case studies and examples that were relevant and
                      effective. {mandonaly}
                    </Text>
                    <Flex className="custom_radio flex_align_end flex_justify_end md_flex_justify_center">
                      <Text className="strong_agree" fw={500} ta="center" mx={10}>
                        Strongly<br></br>Agree
                      </Text>
                      <Radio.Group
                        name="section3_presented"
                        {...form3.getInputProps('section3_presented')}
                      >
                        <Group mt="xs">
                          <Radio value="6" label="6" />
                          <Radio value="5" label="5" />
                          <Radio value="4" label="4" />
                          <Radio value="3" label="3" />
                          <Radio value="2" label="2" />
                          <Radio value="1" label="1" />
                        </Group>
                      </Radio.Group>
                      <Text className="strong_disagree" fw={500} ta="center" mx={10}>
                        Strongly<br></br>Disagree
                      </Text>
                    </Flex>
                  </SimpleGrid>
                  {form3.errors.section3_presented && (
                    <p style={{ color: 'red' }}>{form3.errors.section3_presented}</p>
                  )}
                </Box>
              </Flex>
              {/* Q5 */}
              <Flex w="100%" p={20} my={10} direction="row" wrap="wrap" className="question_box">
                <Box w="100%" my={20}>
                  <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
                    <Text fw={700}>What do you like BEST about the trainer. {mandonaly}</Text>
                    <Flex className="custom_radio flex_align_end flex_justify_end md_flex_justify_center">
                      <Textarea
                        size="md"
                        name="section3_best"
                        placeholder="Your Feedback"
                        w="450px"
                        {...form3.getInputProps('section3_best')}
                      ></Textarea>
                    </Flex>
                  </SimpleGrid>
                </Box>
              </Flex>
              {/* Q6 */}
              <Flex w="100%" p={20} my={10} direction="row" wrap="wrap" className="question_box">
                <Box w="100%" my={20}>
                  <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
                    <Text fw={700}>What do you like LEAST about the trainer. {mandonaly}</Text>
                    <Flex className="custom_radio flex_align_end flex_justify_end md_flex_justify_center">
                      <Textarea
                        size="md"
                        name="section3_least"
                        placeholder="Your Feedback"
                        w="450px"
                        {...form3.getInputProps('section3_least')}
                      ></Textarea>
                    </Flex>
                  </SimpleGrid>
                </Box>
              </Flex>
              {/* Q7 */}
              <Flex w="100%" p={20} my={10} direction="row" wrap="wrap" className="question_box">
                <Box w="100%" my={20}>
                  <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
                    <Text fw={700}>Other comments relating to our Trainer Quality (Optional)</Text>
                    <Flex className="custom_radio flex_align_end flex_justify_end md_flex_justify_center">
                      <Textarea
                        size="md"
                        name="section3_comments"
                        placeholder="Your Feedback"
                        w="450px"
                        {...form3.getInputProps('section3_comments')}
                      ></Textarea>
                    </Flex>
                  </SimpleGrid>
                </Box>
              </Flex>

              <Flex w="100%">
                <Button onClick={prevStep} color="gray" mt="sm">
                  Back
                </Button>
                <Flex className="flex_justify_end" w="100%">
                  <GradientButton type="submit" mt="sm">
                    Next
                  </GradientButton>
                </Flex>
              </Flex>
            </form>
          </Stepper.Step>

          <Stepper.Step>
            {/* Section 4 */}
            <form onSubmit={form4.onSubmit(handleSubmitSection4)} style={{ width: '100%' }}>
              {/* Q1 */}
              <Flex w="100%" p={20} my={10} direction="row" wrap="wrap" className="question_box">
                <Title order={4} my={10} w="100%">
                  Section 4
                </Title>
                <Text>
                  Evaluation on our Trainer Quality<br></br>For each statement below, please choose
                  the response that best represents your opinion
                </Text>
                <Box w="100%" my={20}>
                  <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
                    <Text fw={700}>Interest in other courses we offer.</Text>
                    <Checkbox.Group
                      defaultValue={['']}
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      name="section4_interest"
                      label=""
                      {...form4.getInputProps('section4_interest')}
                      withAsterisk
                    >
                      <Group mt="xs">
                        {allCourse &&
                          allCourse.data &&
                          allCourse.data.map(function (item: any, i: any) {
                            return (
                              <Checkbox
                                key={item.value}
                                w="100%"
                                value={item.value.toString()}
                                label={item.label}
                              />
                            );
                          })}
                      </Group>
                    </Checkbox.Group>
                  </SimpleGrid>
                  {form4.errors.section4_interest && (
                    <p style={{ color: 'red' }}>{form4.errors.section4_interest}</p>
                  )}
                </Box>
              </Flex>

              <Flex w="100%">
                <Button onClick={prevStep} color="gray" mt="sm">
                  Back
                </Button>
                <Flex className="flex_justify_end" w="100%">
                  <GradientButton type="submit" mt="sm">
                    Submit
                  </GradientButton>
                </Flex>
              </Flex>
            </form>
          </Stepper.Step>

          <Stepper.Step>
            <Box bg="#f7e5e5" p={20} ta="center">
              <Title order={3} ta="center">
                {title}
              </Title>
              <Text p={20} ta="center">
                {message}
              </Text>
              <Button onClick={prevStep} color="gray" mt="sm">
                Back
              </Button>
            </Box>
          </Stepper.Step>

          <Stepper.Step>
            <Box bg="#e5f7f2" p={20}>
              <Title order={3} ta="center">
                Thank you for your feedback
              </Title>
              <Text p={20} ta="center">
                We will consider all inputs and suggestions with the aim of improving our programmes
                and enhancing our service delivery. We look forward to seeing you in other FirstCom
                Academy programmes.
              </Text>
            </Box>
          </Stepper.Step>
        </Stepper>
      </Flex>
      <img className="evalution_bg1 sm_hidden" src={bgImage1} alt="bg1" />
      <img className="evalution_bg2 sm_hidden" src={bgImage2} alt="bg2" />
      <img className="evalution_bg3 sm_hidden" src={bgImage3} alt="bg3" />
      <img className="evalution_bg4 sm_hidden" src={bgImage4} alt="bg4" />
    </Box>
  );
}
