import {
  Box,
  Stack,
  Title,
  Flex,
  Image,
  Grid,
  Col,
  TextInput,
  Text,
  Switch,
  Button,
  Modal,
} from '@mantine/core';
import { useEffect, useState } from 'react';
import { useLoginUser } from 'redux/selectors/useLoginUser';
import loading from 'assets/report/loading.gif';
import { GradientButton } from 'components/GradientButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { API } from 'services';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { notifications } from 'notifications';

export function InvoicesDetails() {
  const { loginUser } = useLoginUser();
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [isPdfModal, setIsPdfModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const [pdfName, setPdfName] = useState('');
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const invoice_id = queryParams.get('invoice_id');

  useEffect(() => {
    // prevent horizontal scroll back and forward navigation
    // because our datatable require horizontal scroll alots
    const cached = document.body.style.overscrollBehaviorX;
    document.body.style.overscrollBehaviorX = 'none';
    return () => {
      document.body.style.overscrollBehaviorX = cached;
    };
  }, []);

  // Update height on window resize
  useEffect(() => {
    const updateHeight = () => {
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener('resize', updateHeight);

    // Cleanup the event listener when component unmounts
    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);

  const courseDetail = useQuery(['invoicedetail', invoice_id], () => {
    setLoading(true);
    return API.getInvoiceDetails({
      invoice_id: invoice_id,
    })
      .then((res) => {
        setLoading(false);
        return res.data;
      })
      .catch((error) => {
        setLoading(false);
        throw error;
      });
  });

  const handlePrintInvoice = (invoice_id: any) => {
    setLoading(true);
    return API.printInvoice({
      invoice_id: invoice_id,
    } as any).then((response) => {
      if (response.data.status) {
        // success
        setLoading(false);
        setPdfUrl(response.data.filepath);
        setIsPdfModal(true);
        setPdfName(response.data.fileName);
      } else {
        setLoading(false);
        notifications.error({
          title: response.data.title,
          message: response.data.message,
        });
      }
    });
  };

  return (
    <Stack px={{ base: 0, md: 30 }}>
      {isLoading && (
        <Box className={'loading_box'}>
          <Image src={loading} width="100"></Image>
        </Box>
      )}
      <Flex className={'flex_wrap'}>
        <Box className={'w100 md_w100'}>
          <Box bg="#FFF" p={20}>
            <Box mb={20} className="flex flex_direction_row flex_align_center flex_justify_between">
              <Title order={2}>Invoice Details</Title>
              {invoice_id && (
                <Button px={40} onClick={() => handlePrintInvoice(invoice_id)}>
                  Print Invoice
                </Button>
              )}
            </Box>
            {!isLoading &&
              courseDetail &&
              courseDetail.data &&
              courseDetail.data.trainer_invoice && (
                <Box>
                  <Flex
                    w="100%"
                    p={20}
                    my={10}
                    direction="row"
                    wrap="wrap"
                    style={{ backgroundColor: '#f2fbf9' }}
                  >
                    <Title order={4} my={10} w="100%">
                      Invoice Selected Classes
                    </Title>
                    <hr style={{ width: '100%', borderTop: '1px solid', borderColor: '#ddd' }}></hr>
                    <Box mt={20} w="100%">
                      <Grid>
                        {courseDetail.data &&
                          courseDetail.data.trainer_invoice_courseruns &&
                          courseDetail.data.trainer_invoice_courseruns.length > 0 &&
                          courseDetail.data.trainer_invoice_courseruns.map(function (
                            item: any,
                            i: any
                          ) {
                            return (
                              <Col key={item.courserun_id} span={12} md={12}>
                                <>
                                  <b>
                                    {item.course_title} ({item.course_short_title})
                                  </b>
                                  <br />
                                  <Box className="flex flex_direction_row flex_align_center">
                                    <Col span={6} md={6}>
                                      <Box>
                                        {loginUser.is_corporate == 1 && (
                                          <>
                                            Trainer: {item.trainer_name}
                                            <br />
                                          </>
                                        )}
                                        {item.courserun_course_from} - {item.courserun_course_to}
                                        <br />
                                        Course Run: {item.courserun_apicourserunid}
                                        <br />
                                        Location: {item.traininglocation_title}
                                        <br />
                                        Completed Learners: {item.total_learners}
                                      </Box>
                                    </Col>
                                    <Col span={6} md={6}>
                                      <Box>
                                        Trainer Fee: ${parseFloat(item.totalTrainerRate).toFixed(2)}
                                        <br />
                                        {item.totalTraqomIncentiveRate > 0 && (
                                          <>
                                            Traqom Incentive: $
                                            {parseFloat(item.totalTraqomIncentiveRate).toFixed(2)}
                                            <br />
                                          </>
                                        )}
                                        {item.totalBilingualIncentive > 0 && (
                                          <>
                                            Matured Incentive: $
                                            {parseFloat(item.totalBilingualIncentive).toFixed(2)}
                                            <br />
                                          </>
                                        )}
                                        {item.totalLearnersIncentive > 0 && (
                                          <>
                                            Learners Incentive: $
                                            {parseFloat(item.totalLearnersIncentive).toFixed(2)}
                                            <br />
                                          </>
                                        )}
                                        <b>
                                          Total: $
                                          {parseFloat(
                                            item.totalTrainerRate +
                                              item.totalTraqomIncentiveRate +
                                              item.totalBilingualIncentive +
                                              item.totalLearnersIncentive
                                          ).toFixed(2)}
                                        </b>
                                      </Box>
                                    </Col>
                                  </Box>
                                </>
                                <hr style={{ borderTop: '1px solid', borderColor: '#ddd' }}></hr>
                              </Col>
                            );
                          })}
                      </Grid>
                    </Box>
                  </Flex>
                  <hr
                    style={{ borderTop: '1px solid', borderColor: '#ddd', marginTop: '30px' }}
                  ></hr>
                  <Grid>
                    <Col span={12} sm={12} pb={0}>
                      <Title order={4} w="100%">
                        Invoice Information
                      </Title>
                    </Col>
                    <Col span={12} sm={6}>
                      <TextInput
                        readOnly
                        w="100%"
                        label={<>Invoice No.</>}
                        placeholder="Invoice No."
                        defaultValue={courseDetail.data.trainer_invoice.trainer_invoice_no}
                      />
                    </Col>
                    <Col span={12} sm={6}>
                      <TextInput
                        readOnly
                        w="100%"
                        label={'Invoice Date'}
                        placeholder=""
                        defaultValue={courseDetail.data.trainer_invoice.trainer_invoice_date}
                      />
                    </Col>
                    <Col span={12} sm={6}>
                      <TextInput
                        readOnly
                        w="100%"
                        label="Total Amount ($)"
                        placeholder=""
                        defaultValue={courseDetail.data.trainer_invoice.total_amount}
                      />
                    </Col>
                    <Col span={12} sm={12} mt={10}>
                      <Switch
                        label="Is Paynow?"
                        checked={courseDetail.data.trainer_invoice.is_paynow}
                      />
                    </Col>
                    {courseDetail.data.trainer_invoice.is_paynow == 0 && (
                      <>
                        <Col span={12} sm={6}>
                          <TextInput
                            readOnly
                            w="100%"
                            label="Bank Name"
                            placeholder=""
                            defaultValue={courseDetail.data.trainer_invoice.bank_name}
                          />
                        </Col>
                        <Col span={12} sm={6}>
                          <TextInput
                            readOnly
                            w="100%"
                            label="Bank Account No."
                            placeholder=""
                            defaultValue={courseDetail.data.trainer_invoice.bank_account_number}
                          />
                        </Col>
                        <Col span={12} sm={6}>
                          <TextInput
                            readOnly
                            w="100%"
                            label="Bank Recipient Name"
                            placeholder=""
                            defaultValue={courseDetail.data.trainer_invoice.bank_recipient_name}
                          />
                        </Col>
                      </>
                    )}
                    {courseDetail.data.trainer_invoice.is_paynow == 1 && (
                      <>
                        <Col span={12} sm={6}>
                          <TextInput
                            readOnly
                            w="100%"
                            label="Paynow Mobile No."
                            placeholder=""
                            defaultValue={courseDetail.data.trainer_invoice.paynow_mobile_no}
                          />
                        </Col>
                        <Col span={12} sm={6}>
                          <TextInput
                            readOnly
                            w="100%"
                            label="Paynow UEN"
                            placeholder=""
                            defaultValue={courseDetail.data.trainer_invoice.paynow_uen}
                          />
                        </Col>
                        <Col span={12} sm={6}>
                          <TextInput
                            readOnly
                            w="100%"
                            label="Paynow Recipient Name"
                            placeholder=""
                            defaultValue={courseDetail.data.trainer_invoice.paynow_recipient_name}
                          />
                        </Col>
                      </>
                    )}
                    <Col span={12} sm={12}>
                      <Text className="invoice_date_title" style={{ marginRight: '10px' }}>
                        Status:
                      </Text>
                      {courseDetail.data.trainer_invoice.status == 'Submitted' && (
                        <Text
                          className="invoice_date_title"
                          style={{ color: '#ffbd43', fontWeight: '600' }}
                        >
                          Submitted
                        </Text>
                      )}
                      {courseDetail.data.trainer_invoice.status == 'Rejected' && (
                        <>
                          <Text
                            className="invoice_date_title"
                            style={{ color: '#fa5252', fontWeight: '600' }}
                          >
                            Rejected
                          </Text>
                          <br></br>

                          <Text className="invoice_date_title" style={{ fontWeight: '500' }}>
                            Remarks : {courseDetail.data.trainer_invoice.status_remarks ?? '-'}
                          </Text>
                        </>
                      )}
                      {courseDetail.data.trainer_invoice.status == 'Approved' && (
                        <Text
                          className="invoice_date_title"
                          style={{ color: '#00ab84', fontWeight: '600' }}
                        >
                          Approved
                        </Text>
                      )}
                    </Col>
                  </Grid>

                  {courseDetail.data.trainer_invoice.invoice_allowance &&
                    courseDetail.data.trainer_invoice.invoice_allowance.length > 0 && (
                      <>
                        <hr
                          style={{ borderTop: '1px solid', borderColor: '#ddd', marginTop: '20px' }}
                        ></hr>
                        <Grid>
                          <Col span={12} sm={12} pb={0}>
                            <Box
                              mb={0}
                              className="flex flex_direction_row flex_align_center flex_justify_between"
                            >
                              <Title order={4} w="100%">
                                Allowance
                              </Title>
                            </Box>
                          </Col>
                        </Grid>
                        <Box id={'allowance_inputs'}>
                          {courseDetail.data.trainer_invoice.invoice_allowance.map(
                            (allowance: any, index: any) => (
                              <Grid
                                key={index}
                                style={{
                                  backgroundColor: '#CBEFE7',
                                  paddingBottom: 10,
                                  marginTop: 20,
                                }}
                              >
                                <Col span={12} sm={6}>
                                  <TextInput
                                    readOnly
                                    w="100%"
                                    label="Allowance Title"
                                    placeholder="Allowance Title"
                                    defaultValue={allowance.allowance_title}
                                  />
                                </Col>
                                <Col span={12} sm={6}>
                                  <TextInput
                                    readOnly
                                    w="100%"
                                    label="Allowance Amount ($)"
                                    placeholder="Allowance Amount"
                                    defaultValue={allowance.allowance_amount}
                                  />
                                </Col>
                                <Col span={12} sm={6}>
                                  <Text
                                    className="invoice_date_title"
                                    style={{ marginRight: '10px' }}
                                  >
                                    Attachment:
                                  </Text>
                                  <Image src={allowance.allowance_image} width="150"></Image>
                                </Col>
                              </Grid>
                            )
                          )}
                        </Box>
                      </>
                    )}
                </Box>
              )}

            {!isLoading &&
              courseDetail &&
              courseDetail.data &&
              !courseDetail.data.trainer_invoice && (
                <Box>
                  <Flex
                    w="100%"
                    p={20}
                    my={10}
                    direction="row"
                    wrap="wrap"
                    align={'center'}
                    justify="center"
                    style={{ backgroundColor: '#f2fbf9' }}
                  >
                    <Title order={4}>Invoice Unavailable!</Title>
                  </Flex>
                </Box>
              )}
            <Box></Box>
          </Box>
        </Box>
      </Flex>
      <Modal
        opened={isPdfModal}
        onClose={() => setIsPdfModal(false)}
        title={pdfName}
        size="xl"
        fullScreen
      >
        {/* PDF Viewer (Iframe) */}
        <iframe
          src={pdfUrl}
          width="100%"
          height={screenHeight - 100}
          title="PDF Viewer"
          style={{ border: 'none' }}
        />
      </Modal>
    </Stack>
  );
}
